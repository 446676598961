<he-layout>
    <he-shell scope-query-selector="he-shell" slot="header">
      <shell_he-breadcrumb id="extra-crumbs" *ngIf="breadCrumb">
        <shell_he-breadcrumb-item>{{ breadCrumb }}</shell_he-breadcrumb-item>
      </shell_he-breadcrumb>
    </he-shell>
    <he-task-menu slot="menu" item-type="page" id="menu" class="task-menu" auto-collapse [hidden]="isLeftNaveMenuHidden">
      <he-task-item *ngFor="let menu of menuItems" [selected]="isActive(menu)" (click)="goToPage(menu)">
        {{ menu.label }}
      </he-task-item>
    </he-task-menu>
    <ng-container *ngIf="activeMenu && !isLeftNaveMenuHidden">
      <he-page-title id="b__page-title">
        Integrated design center
        <span slot="active-title">{{ activeMenu.page_title }}</span>
      </he-page-title>   
      <div class="item-first-row-top-margin">
        <he-text appearance="heading-5">{{ activeMenu.sub_title }}</he-text>
        <he-text appearance="paragraph" class="b-info-message-container">{{ activeMenu.sub_title_text }}</he-text>
      </div>
    </ng-container>
    <div class="b-loader-ring-container" style="height: 50vh;" *ngIf="loaderIndicator else content_section">
      <he-progress-ring indeterminate class="loader-ring-main"></he-progress-ring>
    </div>
    <ng-template #content_section>
      <router-outlet></router-outlet>
    </ng-template>
  </he-layout>