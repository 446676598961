import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TemplateManagerComponent } from '../template-manager/template-manager.component';

@Component({
  selector: 'app-create-side-bar',
  templateUrl: './create-side-bar.component.html',
  styleUrls: ['./create-side-bar.component.css']
})
export class CreateSideBarComponent implements OnInit {
  templateInputData: string = '';
  templateOutputData: string = '';
  selectedInputFileName: string = '';
  selectedOutputFileName: string = '';
  inputFileUploaded: boolean = false;
  outputFileUploaded: boolean = false;
  namespace: string = '';
  templateName: string = '';
  availableNamespaces = { myNamespaces: ["SupplierCollab"] };

  @ViewChild(TemplateManagerComponent) templateManager!: TemplateManagerComponent;
  @ViewChild('createPanel') createPanel!: ElementRef;

  constructor(private router: Router) {}

  openCreatePanel(): void {
    this.createPanel.nativeElement.show();
  }

  closeCreatePanel(): void {
    this.createPanel.nativeElement.hide();
  }

  updateValue(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target.id === 'templateNameInput') {
      this.templateName = target.value;
    } else if (target.id === 'templateNamespaceInput') {
      this.namespace = target.value;
    }
  }

  openFileManager(type: 'input' | 'output'): void {
    const fileUploadInput = document.getElementById(type === 'input' ? 'fileUploadInput' : 'fileUploadOutput') as HTMLInputElement;
    fileUploadInput?.click();
  }

  onInputFileSelected(event: Event): void {
    this.handleFileSelected(event, 'input');
  }

  onOutputFileSelected(event: Event): void {
    this.handleFileSelected(event, 'output');
  }

  private handleFileSelected(event: Event, type: 'input' | 'output'): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length) {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (type === 'input') {
          this.templateInputData = e.target?.result as string;
          this.selectedInputFileName = file.name;
          this.inputFileUploaded = true;
        } else {
          this.templateOutputData = e.target?.result as string;
          this.selectedOutputFileName = file.name;
          this.outputFileUploaded = true;
        }
      };
      reader.readAsText(file);
    }
  }

  removeInputFile(): void {
    this.templateInputData = '';
    this.inputFileUploaded = false;
    this.selectedInputFileName = '';
  }

  removeOutputFile(): void {
    this.templateOutputData = '';
    this.outputFileUploaded = false;
    this.selectedOutputFileName = '';
  }

  sendToEditor(): void {
    if (this.namespace === '' || this.templateName === '') {
      return;
    }

    this.router.navigate(['/templateeditor'], {
      state: {
        namespace: this.namespace,
        templateName: this.templateName,
        templateInputData: this.templateInputData,
        templateOutputData: this.templateOutputData,
        selectedInputFileName: this.selectedInputFileName,
        selectedOutputFileName: this.selectedOutputFileName
      }
    });
  }

  ngOnInit(): void {
    this.createPanel.nativeElement.addEventListener('he-fly-in-request-close', (event: CustomEvent) => {
      if (event.detail.source === 'overlay') {
        event.preventDefault();
      }
    });
  }
}
