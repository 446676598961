import { Component, ChangeDetectorRef, ElementRef, EventEmitter, Output, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { TemplateDetailsAPI } from 'src/app/shared/services/ComponentServices/TemplateManagement-api/template-management-api.service';
import { TemplateDetailsModel } from 'src/app/shared/models/template-details-model';
import { TemplateUploadAPI } from 'src/app/shared/services/ComponentServices/TemplateManagement-api/template-management-api.service';
import { TemplateDownloadAPI } from 'src/app/shared/services/ComponentServices/TemplateManagement-api/template-management-api.service';
import { Router } from '@angular/router';
import { TransformationModelDelete } from 'src/app/shared/models/transformation-model-delete';
import { DeleteTemplateAPI } from 'src/app/shared/services/ComponentServices/TemplateManagement-api/template-management-api.service';
import { TemplateViewerComponent } from '../template-viewer/template-viewer.component';
import { DeleteTemplateComponent } from '../delete-template/delete-template.component';
import { CreateSideBarComponent } from '../create-side-bar/create-side-bar.component';
import { UploadSideBarComponent } from '../upload-side-bar/upload-side-bar.component';
import { TransformationModelUpload } from 'src/app/shared/models/transformation-model-upload';
import { TransformationModelDownload } from 'src/app/shared/models/transformation-model-download';
import pagination from '@harmony/enablers/components/pagination/pagination';




@Component({
  selector: 'app-template-manager',
  templateUrl: './template-manager.component.html',
  styleUrls: ['./template-manager.component.css'],
  template: `
    <span [innerHTML]="highlightText(value, searchTerm)"></span>
  `,
  styles: [`
    .highlight {
      background-color: yellow;
    }
  `]
})

export class TemplateManagerComponent implements OnInit {
  currentTab : string = 'all'; //'all', 'favs', 'recycleBin', 'recentlyUpdated'
  favsButtonState: boolean = true;/// true if is Favorite false if is unfavorite
  isLoading: boolean = true;
  valLoaded: boolean = false;
  isDisabled: boolean = true;
  disableAll: boolean = true;
  namespace: string = '';
  templateDeleteSuccess: boolean = false;
  areFavoriteTemplates: boolean = false;
  templateName: string = '';
  deletingTemplateName: string = '';
  nameonscreen: string = '';
  selectedRows: any = [];
  favorites: any = [];
  currentData: Date = new Date();
  currentDateTime: number = 0;
  index : number = 0;
  ///dates in templates will be asigned on ISO format due to recentlyUpdated function receives the data in that format
  /// the dates will be shown in the table in the format dd/mm/yyyy hh:mm using the IsoToOrdDate function
  createdOnExamples: string[] = ["2024-05-07T22:16:54.8082065Z",
                                  "2023-10-28T22:17:54.8962065Z", 
                                  "2022-07-26T23:16:54.8082065Z", 
                                  "2024-04-03T12:16:54.8082065Z", 
                                  "2024-08-08T12:01:54.8082065Z",
                                  "2023-11-25T22:16:54.8082065Z",];
  modifyOnExamples: string[] = ["2024-05-07T22:16:54.8082065Z",
                                 "2023-10-28T22:17:54.8962065Z", 
                                 "2022-07-26T23:16:54.8082065Z", 
                                 "2024-04-03T12:16:54.8082065Z", 
                                 "2024-08-08T12:01:54.8082065Z",
                                 "2023-11-25T22:16:54.8082065Z",];
  currentPage: number = 1;
  pageSize: number = 10;
  totalPages: number = 0;
  searchTerm: string = '';
  templateDetailsARR: TemplateDetailsModel[] = [];
  newTemplate: File = new File ([''], 'newTemplate');
  selectedFile: FormData = new FormData();
  selectedFileName: string = '';
  constructor(
    private templatedetailserivce: TemplateDetailsAPI,
    private templateuploadservice: TemplateUploadAPI,
    private templatedownloadservice: TemplateDownloadAPI,
    private router: Router,
    private templatedeleteservice: DeleteTemplateAPI,
    private cdr: ChangeDetectorRef
  ) {}

  @ViewChild(TemplateViewerComponent) templateViewer!: TemplateViewerComponent;
  @ViewChild(DeleteTemplateComponent) deleteTemplate!: DeleteTemplateComponent;
  


  // OPEN VIEW PANEL
  openView(): void {
    this.templateViewer.openViewPanel();
  }

  // CONFIRM DELETE POPUP
  confirmDelete(): void {
    this.deleteTemplate.confirmDeleteFN();
  }
  
  
    
  rows: 
  { id: number; cells: { field1: string; field2: string; field3: string; field4: string; field5: string; field6: string; field7: string; };
 } [] = [];

  columns = [
    {
      field: 'field1',
      content: 'Template Name',
    },
    {
      field: 'field2',
      content: 'Namespace',
    },
    {
      field: 'field3',
      content: 'Created On [local time]',
    },
    {
      field: 'field4',
      content: 'Created By',
    },
    {
      field: 'field5',
      content: 'Modified On [local time]',
    },
    {
      field: 'field6',
      content: 'Modified By',
    },
    {
      field: 'field7',
      content: 'Favorite',
    },
  ];
    
  rowsUpdated: 
  { id: number; cells: { field1: string; field2: string; field3: string; field4: string; field5: string; field6: string; field7: string; field8: number; };
 } [] = [];




  ngOnInit(): void {
    this.loadData();  
    this.favsButtonState = true;
    this.areFavoriteTemplates = this.allFavorites();
    this.templatedetailserivce.GetTemplateDetails('SupplierCollab').subscribe({
      next: (response) => {
        const templates = JSON.parse(JSON.stringify(response)).templateDetails;
        this.index = 0;
        this.templateDetailsARR = [];
        templates.forEach((template: any) => {
          const detail: TemplateDetailsModel = {
            Namespace: template.nameSpace,
            TemplateName: (template.templateName.replace(template.nameSpace+"/", "")),
            CreatedOn: this.createdOnExamples[this.index%6],
            ModifiedBy: 'ADMIN',
            ModifiedOn: this.modifyOnExamples[this.index%6],
            CreatedBy: 'ADMIN',
            Favorited: this.isItFavorite(template.nameSpace,(template.templateName.replace("SupplierCollab/", ""))),
          };
          this.index++;
          this.templateDetailsARR.push(detail);
        });

        this.totalPages = Math.ceil(this.templateDetailsARR.length / this.pageSize );
        this.rows = [];/// who shows the data in the table
        this.index=0;
        const start = (this.currentPage - 1) * this.pageSize;
        const end = (this.templateDetailsARR.length<start+this.pageSize) ? this.templateDetailsARR.length : start+this.pageSize;
        let valids = 0;
        for (let i =0; i < this.templateDetailsARR.length ; i++) {
          let name = this.templateDetailsARR[i].TemplateName.toLowerCase();
          name=name.replace(".liquid", "");
          if(name.includes(this.searchTerm)){
            valids++;
            if(valids>start && valids<=end){
                this.rows.push({
                  id: this.index + 1,
                  cells: {
                    field1: this.templateDetailsARR[i].TemplateName,
                    field2: this.templateDetailsARR[i].Namespace,
                    field3: this.IsoToOrdDate(this.templateDetailsARR[i].CreatedOn),
                    field4: this.templateDetailsARR[i].ModifiedBy,
                    field5: this.IsoToOrdDate(this.templateDetailsARR[i].ModifiedOn),
                    field6: this.templateDetailsARR[i].CreatedBy,
                    field7: this.templateDetailsARR[i].Favorited ? '⭐' : '',
                  },
              });
              this.index++;
            }
        }
  }
  this.totalPages = Math.ceil(valids / this.pageSize );
      }, 
      error: (error) => {
        console.error('Error:', error);
      }
    });
    (window as any).monaco.languages.register({ id: 'liquid' });
    (window as any).monaco.languages.setMonarchTokensProvider('liquid', {
      tokenizer: {
        root: [
          [/{%.*?%}/, 'delimiter'],
          [/{{.*?}}/, 'delimiter'],
          [/\w+/, 'variable'],
          [/\s+/, 'white']
        ]
      }
    });
  }

  ///AUXILIARS FUNCTIONS
  loadData() {
    setTimeout(() => {
      this.isLoading = false; 
    }, 2000); 
  }

  IsoToOrdDate(isoDate: string): string {  
    ///the object Date transform the date in the local time zone
    const date = new Date(isoDate);

    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero-based
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
   
    // Format the local date and time into the desired string format
    return `${month}-${day}-${year} ${hours}:${minutes}`;
  }

  showTab(): void {
    if(this.currentTab === 'all'){
      this.ngOnInit();
    }
    else if (this.currentTab === 'favs'){
      this.showFavorites();
    }
    else if (this.currentTab === 'recycleBin'){
      this.showRecycleBin();
    }
    else if (this.currentTab === 'recentlyUpdated'){
      this.showRecentlyUpdated();
    }
  }
  
////FAVORITE SECTION
isItFavorite(templateNamespace: string, templateName: string): boolean {
    var returnValue =  this.favorites.find((favTemplate: TemplateDetailsModel) => favTemplate.Namespace === templateNamespace && favTemplate.TemplateName === templateName)? true : false;
    return returnValue;
}
addToFavorites(): void {
    this.selectedRows.forEach((element: any) => {
      
      var selectedRow: TemplateDetailsModel = {
        Namespace: element.cells.field2,
        TemplateName: element.cells.field1,
        CreatedOn: element.cells.field3,
        ModifiedBy: element.cells.field6,
        ModifiedOn: element.cells.field5,
        CreatedBy: element.cells.field4,
        Favorited: element.cells.field7,
      };
      
      var isAlreadyFavorit = this.favorites.find((favorite: TemplateDetailsModel) => favorite.TemplateName === selectedRow.TemplateName && favorite.Namespace === selectedRow.Namespace);
      if(isAlreadyFavorit===undefined){
        this.favorites.push(selectedRow)
      }

      var id = this.rows.findIndex((row: any) => row.cells.field1 === selectedRow.TemplateName && row.cells.field2 === selectedRow.Namespace);
      this.rows[id].cells.field7 === '⭐';
      this.templateDetailsARR[id].Favorited = false;
    });
    this.onSelectRowChange(this.selectedRows);
    this.showTab();
}
  
makeUnfavorite(): void {
    this.selectedRows.forEach((element: any) => {
      var selectedRow: TemplateDetailsModel = {
        Namespace: element.cells.field2,
        TemplateName: element.cells.field1,
        CreatedOn: element.cells.field3,
        ModifiedBy: element.cells.field6,
        ModifiedOn: element.cells.field5,
        CreatedBy: element.cells.field4,
        Favorited: element.cells.field7,
      };
      
      var index = this.favorites.findIndex((favorite: TemplateDetailsModel) => favorite.TemplateName === selectedRow.TemplateName && favorite.Namespace === selectedRow.Namespace);
      if (index !== -1) {
      this.favorites.splice(index, 1);
    }

      var id = this.rows.findIndex((row: any) => row.cells.field1 === selectedRow.TemplateName && row.cells.field2 === selectedRow.Namespace);
      this.rows[id].cells.field7 === '';
      this.templateDetailsARR[id].Favorited = false;
    });
  
    this.onSelectRowChange(this.selectedRows);
    this.showTab();
}
showFavorites(): void {
    this.loadData(); 
    this.rows = [];
    this.index = 0;
    this.totalPages = Math.ceil(this.favorites.length / this.pageSize );
    const start = (this.currentPage - 1) * this.pageSize;
    const end = (this.favorites.length<start+this.pageSize) ? this.favorites.length : start+this.pageSize;
    let valids = 0;
   for(let i =0; i < this.favorites.length ; i++){
    let name = this.favorites[i].TemplateName.toLowerCase();
    name = name.replace(".liquid", "");
    if(name.includes(this.searchTerm)){
      valids++;
      if(valids>start && valids<=end){
          this.rows.push({
            id: this.index + 1,
            cells: {
              field1: this.favorites[i].TemplateName,
              field2: this.favorites[i].Namespace,
              field3: this.favorites[i].CreatedOn,
              field4: this.favorites[i].CreatedBy,
              field5: this.favorites[i].ModifiedOn,
              field6: this.favorites[i].ModifiedBy,
              field7: '⭐',
            },
          });
          this.index++;
        }
   }
  this.totalPages = Math.ceil(valids / this.pageSize )
  }

}
handleButtonClickFavoriteButton() {
 if(this.favsButtonState === true){
  this.addToFavorites();
 }
 else{
  this.makeUnfavorite();
 }
}

allFavorites(): boolean {
  return this.selectedRows.every((element: any) => element.cells.field7 === '⭐');
}
 setFavButtonState(): void {
  if(this.selectedRows.length === 0){
    this.favsButtonState = true;
  }
  else{
    if(this.allFavorites()){
      this.favsButtonState = false; 
    }
    else{
      this.favsButtonState = true; 
    }
  }
 }

 ////RECYLE BIN SECTION
 showRecycleBin() {
  this.loadData();
  throw new Error('Method not implemented.');
}

 ///RECENTLY UPDATED SECTION
 
 showRecentlyUpdated() {
  this.loadData();
  this.totalPages=1;
  this.rowsUpdated = [];
  this.currentDateTime = this.currentData.getTime();
  this.index=0;
  for(let i = 0; i < this.templateDetailsARR.length; i++){
   
        this.rowsUpdated.push({
          id: this.index + 1,
          cells: {
            field1: this.templateDetailsARR[i].TemplateName,
            field2: this.templateDetailsARR[i].Namespace,
            field3: this.templateDetailsARR[i].CreatedOn,
            field4: this.templateDetailsARR[i].CreatedBy,
            field5: this.templateDetailsARR[i].ModifiedOn,
            field6: this.templateDetailsARR[i].ModifiedBy,
            field7: this.templateDetailsARR[i].Favorited ? '⭐' : '',
            field8: this.currentDateTime - new Date(this.templateDetailsARR[i].ModifiedOn).getTime(),
          },
        }); 
      this.index++;
    
  }       
  this.quickSort(this.rowsUpdated, 0, this.rowsUpdated.length - 1);
  this.rows = [];
  for(let i = 0; i < this.rowsUpdated.length && i<5; i++){
    let name = this.templateDetailsARR[i].TemplateName.toLowerCase();
    name=name.replace(".liquid", "");
    if(name.includes(this.searchTerm)){
      this.rows.push({
        id: i + 1,
        cells: {
          field1: this.rowsUpdated[i].cells.field1,
          field2: this.rowsUpdated[i].cells.field2,
          field3: this.IsoToOrdDate(this.rowsUpdated[i].cells.field3),
          field4: this.rowsUpdated[i].cells.field4,
          field5: this.IsoToOrdDate(this.rowsUpdated[i].cells.field5),
          field6: this.rowsUpdated[i].cells.field6,
          field7: this.rowsUpdated[i].cells.field7,
        },
      }); 
    }
  }
  
}

partition(arr: any[], low: number, high: number): number {
  let pivot = arr[high].cells.field8;
  let i = low-1;
  for (let j=low;j<=high-1;j++) {
      if (arr[j].cells.field8 < pivot) {
          i++;
          [arr[i],arr[j]] = [arr[j],arr[i]]; 
      }
  }

  [arr[i+1],arr[high]] = [arr[high],arr[i+1]];
  return i+1; 
}

quickSort(arr: any[], low: number, high: number): void{
  if (low < high) {
      let pi = this.partition(arr, low, high);

      this.quickSort(arr, low, pi - 1);
      this.quickSort(arr, pi + 1, high);
  }
}

//////PAGINATION SECTION


handlePageChange(event: any): void {
  this.currentPage = event.detail;
  this.showTab(); 
}

///// SEARCH SECTION: cHANGE THE SEARCH TERM AND UPDATE THE TABLE
onSearchInput(event: Event): void {
  const inputElement = event.target as HTMLInputElement;
  this.searchTerm = inputElement.value.trimStart().toLowerCase();
  this.showTab();
}


// UPDATE VARIABLES AS USER TYPES
  updateValue(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target.id === 'templateNameInput') {
      this.templateName = target.value;
    } else if (target.id === 'templateNamespaceInput') {
      this.namespace = target.value;
    }
  }


  //avoid repeated names
  isTemplateNameUnique(templateName: string, namespace: string): boolean {
    console.log('Checking for unique template name');
    for (let i = 0; i < this.templateDetailsARR.length; i++) {
      if (this.templateDetailsARR[i].TemplateName.toLowerCase() === templateName.toLowerCase() &&
          this.templateDetailsARR[i].Namespace.toLowerCase() === namespace.toLowerCase()) {
        return false;
      }
    }
    return true;
  }

    // SEND TO EDITOR PAGE FOR CREATING OR EDIITING 
    sendToEditor(): void {
        if (this.namespace === '' || this.templateName === '') {
            console.error('Namespace or Template Name is empty!');
            return;
        }
        this.router.navigate(['/templateeditor'], { state: { namespace: this.namespace, templateName: this.templateName } });
    }

    // DELETE TEMPLATES
    postDataToDeleteTemplateAPI(): void {
        this.selectedRows.forEach((selectedRow: any) => {
            const templateToDelete: TransformationModelDelete = {
                Namespace: selectedRow.cells.field2,
                TemplateName: selectedRow.cells.field1
            };
            this.deletingTemplateName = this.selectedRows[0].cells.field1;


            this.templatedeleteservice.postDeleteTemplate(templateToDelete).subscribe({
                next: (response: any) => {
                    console.log('Response:', response);
                    const selectedIndex = this.rows.findIndex(row => row.id === selectedRow.id);
                    if (selectedIndex !== -1) {
                        this.rows = this.rows.filter(row => row.id !== selectedRow.id);
                    }
                },
                error: (error) => {
                    console.error('Error:', error);
                    if (error.error instanceof ErrorEvent) {
                        // Client-side error
                        console.error('Error Event:', error.error.message);
                    } else {
                        // Server-side error
                        console.error(`Server-side error: ${error.status} - ${error.message}`);
                    }
                }
            });
        });

        this.templateDeleteSuccess = true;
        console.log('Templates Deleted Successfully!');
        setTimeout(() => {
            this.templateDeleteSuccess = false;
        }, 5000);
    }


  onSelectRowChange(event: any): void {
    const dataGrid = document.querySelector('he-data-grid') as HTMLElement;
    const selectedRows = (dataGrid as any).selectedRows;
    this.selectedRows = selectedRows;
    
    this.isDisabled = selectedRows.length !== 1;
    this.disableAll = selectedRows.length === 0;
    this.areFavoriteTemplates = this.allFavorites();
    this.setFavButtonState();

      this.templateName = this.selectedRows[0].cells.field1;
      this.namespace = this.selectedRows[0].cells.field2;

    }



    @ViewChild (CreateSideBarComponent) createPanel!: CreateSideBarComponent;



    openCreatePanel() {
      console.log('im in create template');
    console.log('Create Template', this.namespace, this.templateName);
    
        this.createPanel.openCreatePanel();

        if(this.namespace === '' || this.templateName === ''){
          console.error('Namespace or Template Name is empty! templatecomponent');
          return;
        }
        if(this.isTemplateNameUnique(this.templateName, this.namespace)===false){
          console.error('repeated template!');
          return;
        }
    }

    @ViewChild(UploadSideBarComponent) uploadPanel!: UploadSideBarComponent;

    openUploadPanel() {
        this.uploadPanel.openUploadPanel();
    }
    
}
