import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, filter } from 'rxjs';
import { menuItemList } from './layout.constant';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  navStart: Observable<NavigationStart>;
  isIframe = false;
  nav!: NavigationStart;
  breadCrumb = '';
  baseUrl = '/templatemanager';
  isLeftNaveMenuHidden = true;
  menuItems = menuItemList;
  activeMenu: any = {};
  loaderIndicator: boolean = true;
  @ViewChild('navHeaderMenu') navHeaderMenu!: ElementRef;
  @ViewChild('loginPanel') loginPanel!: ElementRef;

  constructor(private router: Router) {
    this.navStart = router.events.pipe(
      filter((evt) => evt instanceof NavigationStart)
    ) as Observable<NavigationStart>;
  }

  ngOnInit(): void {
    this.navStart.subscribe((nav) => {
      this.nav = nav;
      if (this.nav?.url == '/') {
        this.nav.url = this.baseUrl;
      }
        this.isLeftNaveMenuHidden = false;
        let lastIndexOf = undefined;
        if (this.nav?.url.indexOf('?') >= 0) {
          lastIndexOf = this.nav?.url.indexOf('?');
        }
        const urlEndString = this.nav?.url?.substring(
          this.nav?.url.lastIndexOf('/') + 1,
          lastIndexOf
        );
        this.breadCrumb = 'Template Manager';
        let selectedMenu = this.menuItems.filter(
          (x) => x.slug === urlEndString
        );
        if (selectedMenu && selectedMenu.length > 0) {
          this.activeMenu = selectedMenu[0];
          this.loaderIndicator = false;
        } else {
          this.isLeftNaveMenuHidden = true;
          this.loaderIndicator = false;
          this.breadCrumb = '';
        }
      
    });
  }

  onMenuItemClickEvent() {
    const shadowElement =
      this.navHeaderMenu?.nativeElement?.shadowRoot?.querySelector(
        'he-fly-in-panel'
      );
    shadowElement.open = false;
  }

  isActive(menu: any) {
    let lastIndexOf = undefined;
    if (this.nav?.url !== '/') {
      if (this.nav?.url.indexOf('?') >= 0) {
        lastIndexOf = this.nav?.url.indexOf('?');
      }
      const urlEndString = this.nav?.url?.substring(
        this.nav?.url.lastIndexOf('/') + 1,
        lastIndexOf
      );
      if (urlEndString === menu.route) return true;
      else return false;
    } else {
      return false;
    }
  }

  goToPage(menu: any) {
    menu.selected = true;
    this.activeMenu = menu;
    this.router.navigate(['/' + menu.route]);
  }

  onPanelOpen() {
    this.loginPanel?.nativeElement?.show();
  }
}
