import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpCoreService {
  baseApiUrl = environment.api_base_url;
  secondaryApiUrl = environment.secondary_api_base_url;

  constructor(private http: HttpClient) {}

  get(
    endPoint: string,
    params?:
      | HttpParams
      | {
        [param: string]:
        | string
        | number
        | boolean
        | readonly (string | number | boolean)[];
      }
      | undefined,
      useSecondaryApi: boolean = false
  ): Observable<any> {
    const baseUrl = useSecondaryApi ? this.secondaryApiUrl : this.baseApiUrl;
    const endPointUrl = baseUrl + endPoint;

    return this.http.get(endPointUrl, { params: params });
  }

  put(
    endPoint: string,
    body?: any,
    params?:
      | HttpParams
      | {
        [param: string]:
        | string
        | number
        | boolean
        | readonly (string | number | boolean)[];
      }
      | undefined,
      useSecondaryApi: boolean = false
  ): Observable<any> {
    const baseUrl = useSecondaryApi ? this.secondaryApiUrl : this.baseApiUrl;
    const endPointUrl = baseUrl + endPoint;

    return this.http.put(endPointUrl, body, { params: params });
  }

  post(
    endPoint: string,
    body?: any,
    params?:
      | HttpParams
      | {
        [param: string]:
        | string
        | number
        | boolean
        | readonly (string | number | boolean)[];
      }
      | undefined,
      useSecondaryApi: boolean = false
  ): Observable<any> {
    const baseUrl = useSecondaryApi ? this.secondaryApiUrl : this.baseApiUrl;
    const endPointUrl = baseUrl + endPoint;

    return this.http.post(endPointUrl, body, { params: params });
  }
}
