
<he-tabs style="font-weight: bold;">
	<he-tab appearance="command" (click)="this.currentTab='all'; this.isLoading = true; this.searchTerm=''; this.currentPage=1; this.ngOnInit()">All </he-tab> 

	<he-tab appearance="command" (click)="this.currentTab='recentlyUpdated'; this.isLoading = true;this.searchTerm=''; this.currentPage=1; this.showRecentlyUpdated()">Recently updated </he-tab> 

	<he-tab appearance="command" (click)="this.currentTab='favs'; this.isLoading = true; this.searchTerm=''; this.currentPage=1; this.showFavorites()">Favorites </he-tab>

	<he-tab appearance="command" (click)="this.currentTab='recycleBin'; this.isLoading = true; this.searchTerm=''; this.currentPage=1; this.showRecycleBin()">Recycle Bin </he-tab> 

	<he-tab-panel class="custom-tab-panel"></he-tab-panel>
	<he-tab-panel class="custom-tab-panel"></he-tab-panel>
	<he-tab-panel class="custom-tab-panel"></he-tab-panel>
	<he-tab-panel class="custom-tab-panel"></he-tab-panel>
	</he-tabs>
<he-message-bar duration="5000" appearance="success" [open]="templateDeleteSuccess" no-close-button>
	<ng-container *ngIf="selectedRows.length === 1; else multipleDelete">
	  {{deletingTemplateName}} has been permanently deleted.
	</ng-container>
	<ng-template #multipleDelete>
	  {{selectedRows.length}} items have been permanently deleted.
	</ng-template>
  </he-message-bar>
  
<div *ngIf="isLoading" class="loading-indicator">
		<he-data-grid 
		class="data-grid-margin"
		id="templates-data-grid" 
		label="Data Grid with Command-bar Example"
		select="multiple"
		[loading]=true
		[columns]="columns"
		[rows]="rows"
		select-on-click
		(he-row-select-change)="onSelectRowChange($event)"
		(he-select-all-change)="onSelectRowChange($event)">
	
	
	<div slot="data-grid-controls" class="table-commands" style="display: flex; width: 100%;">
		<he-command-bar style="flex: 5; display:flex; width:100%;">
	
			<div class="left-align" style="flex-grow:1;">
				<he-dropdown fixed-placement>
					<he-button appearance="command" slot="trigger" caret>
					<he-icon slot="start" name="add"></he-icon>
					Add
					</he-button>
					<he-menu>
					<he-menu-item>Create Template</he-menu-item>
					<he-menu-item>Upload Template</he-menu-item>
					</he-menu>
				</he-dropdown>
		
				  <he-button appearance="command" [disabled]="isDisabled">
					  <he-icon slot="start" name="view"></he-icon>
					  View
				  </he-button>
				  <he-button appearance="command" [disabled]="isDisabled">
					  <he-icon slot="start" name="edit"></he-icon>
					 Edit
				  </he-button>
				  <he-button appearance="command" [disabled]="disableAll">
					  <he-icon slot="start" name="delete"></he-icon>
					Delete	
				  </he-button>
				
				  <he-button appearance="command" [disabled]="disableAll" (click)="handleButtonClickFavoriteButton()">
					  <he-icon slot="start" name="addfavorite"></he-icon>
					  <span>{{ this.favsButtonState ? 'Favorite' :  'Unfavorite'}}</span>
				  </he-button>
	
			</div>
			<div class="right-align" style="margin-left: auto;">
				<he-button appearance="command" slot="action-end" >
					<he-icon slot="start" name="filter" [disabled]="isDisabled"></he-icon>
					Filter
				</he-button>
				<he-search-box slot="search" label="Search" hide-label ></he-search-box>
			</div>
		</he-command-bar>
	  </div>
	
		<span slot="no-records">No search results found.</span>
		  <span slot="loading">Loading...</span>
	</he-data-grid>
	
</div>
<div *ngIf="!isLoading" >
	<he-data-grid 
	class="data-grid-margin"
	id="templates-data-grid" 
	label="Data Grid with Command-bar Example"
	select="multiple"
	[columns]="columns"
	[rows]="rows"
	select-on-click
	(he-row-select-change)="onSelectRowChange($event)"
	(he-select-all-change)="onSelectRowChange($event)"
	>


<div slot="data-grid-controls" class="table-commands" style="display: flex; width: 100%;">
	<he-command-bar style="flex: 5; display:flex; width:100%;">

		<div class="left-align" style="flex-grow:1;">
			<he-dropdown fixed-placement>
				<he-button appearance="command" slot="trigger" caret>
				<he-icon slot="start" name="add"></he-icon>
				Add
				</he-button>
				<he-menu>
				<he-menu-item (click)="openCreatePanel()">Create Template</he-menu-item>
				<he-menu-item (click)="openUploadPanel()">Upload Template</he-menu-item>
				</he-menu>
			</he-dropdown>
	
	  		<he-button appearance="command" [disabled]="isDisabled" (click)="openView()">
				<he-icon slot="start" name="view"></he-icon>
			View
			</he-button>
	  		<he-button appearance="command" [disabled]="isDisabled" (click)="sendToEditor()">
				<he-icon slot="start" name="edit"></he-icon>
			Edit
			</he-button>
	  		<he-button appearance="command" [disabled]="isDisabled" (click)="confirmDelete()">
				<he-icon slot="start" name="delete"></he-icon>
			Delete
			</he-button>
			
	  		<he-button appearance="command" [disabled]="disableAll" (click)="handleButtonClickFavoriteButton()">
		  		<he-icon slot="start" name="addfavorite"></he-icon>
				  <span>{{ this.favsButtonState ? 'Favorite' :  'Unfavorite'}}</span>
	  		</he-button>

		</div>
		<div class="right-align" style="margin-left: auto;">
			<!--<he-button appearance="command" slot="action-end" >
				<he-icon slot="start" name="filter" [disabled]="isDisabled"></he-icon>
				Filter
			</he-button>-->
			<he-search-box slot="search" label="Search" hide-label (he-input)="onSearchInput($event)"></he-search-box>
		</div>
	</he-command-bar>
	
  </div>
	<he-pagination 
      id="pagination" 
      slot="pagination" 
	  overflow-position="top"
      [pageCount]="totalPages"
	  (he-page-change)="handlePageChange($event)" >
    </he-pagination>
	

<span slot="no-records">No search results found.</span>
<span slot="loading">Loading...</span>
</he-data-grid>

<!-- DELETE POPUP COMPONENT -->
  <app-delete-template
  [selectedRows]="selectedRows" 
  [templateName]="templateName"
  [namespace]="namespace"
  (triggerDelete)="postDataToDeleteTemplateAPI()"
  ></app-delete-template>

  <!-- VIEW PANEL COMPONENT -->
  <app-template-viewer 
  [selectedRows]="selectedRows" 
  [templateName]="templateName"
  [namespace]="namespace"
  (triggerDelete)="postDataToDeleteTemplateAPI()"></app-template-viewer>

<app-create-side-bar>	</app-create-side-bar>
<app-upload-side-bar>	</app-upload-side-bar>



</div>
