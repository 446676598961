import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TemplateDetailsAPI } from 'src/app/shared/services/ComponentServices/TemplateManagement-api/template-management-api.service';
import { TemplateUploadAPI } from 'src/app/shared/services/ComponentServices/TemplateManagement-api/template-management-api.service';
import { TransformationModelDownload } from 'src/app/shared/models/transformation-model-download';
import { TemplateDownloadAPI } from 'src/app/shared/services/ComponentServices/TemplateManagement-api/template-management-api.service';
import { Router } from '@angular/router';
import { DeleteTemplateAPI } from 'src/app/shared/services/ComponentServices/TemplateManagement-api/template-management-api.service';
import { TemplateManagerComponent } from '../template-manager/template-manager.component';
import { DeleteTemplateComponent } from '../delete-template/delete-template.component';

@Component({
  selector: 'app-template-viewer',
  templateUrl: './template-viewer.component.html',
  styleUrls: ['./template-viewer.component.css']
})
export class TemplateViewerComponent implements OnInit {
  @Input() isLoading: boolean = false;
  @Input() templateName: string = '';
  @Input() namespace: string = '';
  @Input() selectedRows: any[] = [];

  constructor(
    private templatedetailserivce: TemplateDetailsAPI,
    private templateuploadservice: TemplateUploadAPI,
    private templatedownloadservice: TemplateDownloadAPI,
    private templatedeleteservice: DeleteTemplateAPI,
    private router: Router
  ) { }

  @Output() triggerDelete = new EventEmitter<void>();
  @Output() triggerDownload = new EventEmitter<void>();
  @Output() triggerEdit = new EventEmitter<void>();

  @ViewChild(TemplateManagerComponent) templateManager!: TemplateManagerComponent;
  @ViewChild(DeleteTemplateComponent) deleteTemplate!: DeleteTemplateComponent;


  ngOnInit(): void {
    (window as any).monaco.languages.register({ id: 'liquid' });

    (window as any).monaco.languages.setMonarchTokensProvider('liquid', {
      tokenizer: {
        root: [
          [/\{\{/, 'delimiter'],
          [/\}\}/, 'delimiter'],
          [/\{\%/, 'delimiter'],
          [/\%\}/, 'delimiter'],
          [/[a-z_$][\w$]*/, 'identifier'],
          [/[ \t\r\n]+/, 'whitespace'],
          [/".*?"/, 'string'],
          [/'(\\'|.)*?'/, 'string'],
        ]
      }
    });
  }

  liquidEditorStyle = {
    height: '700px',
    width: '1250px'
  };
  liquidEditorOptions = {
    theme: 'vs-dark',
    language: 'liquid',
    automaticLayout: true,
    readOnly: true
  };
  liquidCode: string = `{% if user %}
    Hello {{ user.name }}!
  {% endif %}`;

  @ViewChild('viewPanel') viewPanel!: ElementRef;

  openViewPanel() {
    this.isLoading = true;
    var templateToView: TransformationModelDownload = {
      Namespace: this.selectedRows[0].cells.field2,
      TemplateName: this.selectedRows[0].cells.field1
    };
    this.templatedownloadservice.PostTemplateDownload(templateToView).subscribe({
      next: (response: any) => {
        console.log('Response:', response);
        this.liquidCode = response.content;
        this.isLoading = false;
      },
      error: (error) => {
        console.error('Error:', error);
        if (error.error instanceof ErrorEvent) {
          // Client-side error
          console.error('Error Event:', error.error.message);
          this.isLoading = false;
        } else {
          // Server-side error
          console.error(`Server-side error: ${error.status} - ${error.message}`);
          this.isLoading = false;
        }
      },
      complete: () => {
        console.log('Loding Complete!: ' + this.selectedRows);
      }
    });
    this.templateName = this.selectedRows[0].cells.field1;
    this.namespace = this.selectedRows[0].cells.field2;
    this.viewPanel.nativeElement.show();
  }

  closeViewPanel() {
    this.viewPanel.nativeElement.hide();
  }

  confirmDeletePOPUP(): void {
    this.deleteTemplate.confirmDeleteFN();
  }

  deleteTemplateAfterConfirm(): void {
    this.triggerDelete.emit();
  }


    // DOWNLOAD TEMPLATE
    downloadTemplate(): void {
      if(this.namespace === '' || this.templateName === ''){
        console.error('Namespace or Template Name is empty!');
        return;
      }
      var downloadedTemplate : TransformationModelDownload = {
        Namespace: this.namespace,
        TemplateName: !this.templateName.includes('.liquid') ? this.templateName + '.liquid' : this.templateName
      };
      this.templatedownloadservice.PostTemplateDownload(downloadedTemplate).subscribe({
        next: (response: any) => {
          console.log('Response:', response);
          const blob = new Blob([response.content], { type: 'application/json' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          // This will prepopulate the filename in the save dialog
          a.download = this.templateName; 
          document.body.appendChild(a);
          a.click(); // This will trigger the download
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        },
        error: (error) => {
          console.error('Error:', error);
          if (error.error instanceof ErrorEvent) {
            console.error('Error Event:', error.error.message);
          } else {
            console.error(`Server-side error: ${error.status} - ${error.message}`);
          }
        }
      });
    }

  editTemplate() {
    if(this.namespace === '' || this.templateName === ''){
      console.error('Namespace or Template Name is empty!');
      return;
    }
    this.router.navigate(['/templateeditor'], {state: {namespace: this.namespace, templateName: this.templateName}});
  }
  
}
