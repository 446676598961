import { Liquid } from 'liquidjs';
import { Injectable } from '@angular/core';
import { TransformationModelExecute } from 'src/app/shared/models/transformation-model-execute';

@Injectable({
    providedIn: 'root'
})
export class TemplateExecutionService {
    engine: Liquid = new Liquid();
    executed_output: string = "";

    constructor() { }

    async executeTemplate(request: TransformationModelExecute): Promise<string> {
        this.executed_output = await this.engine.parseAndRender(request.Template, JSON.parse(request.Input));
        return this.executed_output;
    }
}