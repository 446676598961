<he-card>
  <div class="top-buttons-container">
  <he-button appearance="command" (click)="generateAiTemplate()"> Generate Template</he-button>
    <button class="close-button" (click)="triggerClose()">
      <he-icon name="chromeclose"></he-icon>
    </button>
  </div>

    <he-text-area 
        id="copilot-input"
        spellcheck="true" 
        style="width: 95%;" 
        resize="vertical"
        label="Copilot" 
        placeholder="Tell Copilot what to change in the template" 
        (he-input)="updateValue($event)"
        (keydown.enter)="handleAiSubmission($event)">
            <span slot="label" class="custom-label">Design Center Copilot</span>
    </he-text-area>
    
    <div class="button-container">
      <he-button appearance="stealth" (click)="handleAiSubmission($event)">
        <he-icon name="send"></he-icon>
      </he-button>
    </div>
</he-card>
  