
<he-dialog [style.--width]="'600px'" 
#confirmDelete
id='confirmDelete'
[heading]="selectedRows.length > 1 ? 'Are you sure you want to delete ' + selectedRows.length + ' items?' : 'Are you sure you want to delete ' + templateName + '?'" 
class="dialog-deny-close">
	<p>The selected file(s) will be permanently deleted.</p>
  
	<he-button slot="footer" appearance="primary" (click)="deleteTemplate(); closeViewPanel(); closeDeleteFN()">Delete</he-button>
	<he-button slot="footer" (click)="closeDeleteFN()">Cancel</he-button>
  </he-dialog>


