import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import {scope} from 'src/scope';
import { DeleteTemplateComponent } from './components/delete-template/delete-template.component';
import { AppRoutingModule } from './app-routing.module';
import { LayoutComponent } from './shared/components/layout/layout.component';
import { TemplateManagerComponent } from './components/template-manager/template-manager.component';
import { TemplateEditorComponent } from './components/template-editor/template-editor.component';
import { FormsModule } from '@angular/forms';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { ResizableModule } from 'angular-resizable-element';
import { TemplateViewerComponent } from './components/template-viewer/template-viewer.component';
import { UploadSideBarComponent } from './components/upload-side-bar/upload-side-bar.component';
import { CreateSideBarComponent } from './components/create-side-bar/create-side-bar.component';
import { TemplateAiComponent } from './components/template-ai/template-ai.component';



scope.registerComponent();
@NgModule({
  declarations: [
    AppComponent,
    DeleteTemplateComponent,
    LayoutComponent,
    TemplateManagerComponent,
    TemplateEditorComponent,
    TemplateViewerComponent,
    UploadSideBarComponent,
    CreateSideBarComponent,
    TemplateAiComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ResizableModule,
    MonacoEditorModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
