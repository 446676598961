<!-- header including title and buttons -->
<div class="header">
    <he-text appearance="heading-3"><strong>Integrated design center</strong> | Editor</he-text>
    <div class="middle-buttons">
        <he-button appearance="command" (click)="toggleAiTemplate()">
            <he-icon slot="start" name="sparkle"></he-icon>
            Copilot
        </he-button>
        <he-button appearance="command">
            <he-icon slot="start" name="info"></he-icon>
            Info
        </he-button>
    </div>
    <div class="right-buttons">
        <he-button appearance="command" (click)="confirmSubmit()">
            <he-icon slot="start" name="publish"></he-icon>
            Submit
        </he-button>
        <he-button appearance="command" (click)="sendToTemplateManager()">
            <he-icon slot="start" name="cancel"></he-icon>
            Cancel
        </he-button>
    </div>
    
</div>
<!-- three columns layout. Left, middle and right columns -->
<div class="flex-container">
    <!-- left column -->
    <div class="left-column">
        <!-- input -->
         
        <he-text appearance="heading-4" class="title">Input</he-text>
        <div class="box">
            <he-text appearance="paragraph" *ngIf="selectedInputFileName">{{selectedInputFileName}}</he-text> 
            <he-text appearance="paragraph" *ngIf="!selectedInputFileName">Input JSON</he-text>
            <he-button appearance="command" (click)="removeInputFile()" >
                <he-icon slot="start" name="errorbadge"></he-icon>
                Remove
            </he-button>
            <he-button appearance="command" (click)="openFileManager('input')">
                <he-icon slot="start" name="download"></he-icon>
                Import file
                <input type="file" id="fileUploadInput" style="display: none;" (change)="onInputFileSelected($event)">
            </he-button>
        </div>
        <ngx-monaco-editor class="liquid-editor" [options]="inputOutputEditorOptions" [(ngModel)]="templateInputData" [ngStyle]="JSONeditorStyle" automaticLayout (ngModelChange)="onTemplateChange()"></ngx-monaco-editor>
    </div>
    <!-- middle column -->
    <div class="middle-column">
        <he-message-bar  *ngIf="uploadSucess" appearance="success" open no-close-button duration="5000">
            Template Uploaded Successfully! 
         </he-message-bar>
        <he-text appearance="heading-4" class="title">Template</he-text>
        <div class="box">
            <he-text appearance="paragraph">{{templateName}} 
                    <he-message-bar *ngIf="aiErrorResponse" duration="3000" appearance="error" open no-close-button>I am Taz and can't do that. Please try again or ask me for something else.</he-message-bar>
                    <he-message-bar *ngIf="aiChangesMade" appearance="info" open no-close-button>Do you want to accept or discard the changes?
                        <he-button slot="actions" class="icon-button" appearance="command" *ngIf="aiChangesMade" (click)="acceptChanges()">
                            <he-icon slot="start" name="accept"></he-icon>
                        </he-button>
                        <he-button slot="actions" class="icon-button" appearance="command" *ngIf="aiChangesMade" (click)="declineChanges()">
                            <he-icon slot="start" name="cancel"></he-icon>
                        </he-button>
                    </he-message-bar>
            </he-text> 
        </div>
        <div class="editor-copilot-box">
<!--             <he-callout #acceptDiscardPopup heading="Review Edits" (he-popup-hide)="declineChanges()">
                <p *ngIf="aiErrorResponse">I am Taz and can't do that. Please try again or ask me for something else.</p>
                <p *ngIf="!aiErrorResponse">Do you want to accept or discard the changes?</p>
            </he-callout> -->
            <he-dialog heading="Are you sure you want to submit this template?" #areYouSureSubmit>  
                <p>
                    By submitting this template, you will be able to use it in the design center.
              </p>
            
              <he-button slot="footer" appearance="primary" (click)="submitTemplate()"> Yes </he-button>
              <he-button slot="footer" (click)="closeSubmit()"> Cancel </he-button> 
            </he-dialog>
            <he-progress *ngIf="aiChangesLoading" label="Progress" indeterminate></he-progress>
            <ngx-monaco-editor *ngIf="!aiChangesMade" class="liquid-editor" [options]="templateEditorOptions" [(ngModel)]="templateCode" [ngStyle]="JSONeditorStyle" automaticLayout (ngModelChange)="onTemplateChange()"></ngx-monaco-editor>
            <ngx-monaco-diff-editor *ngIf="aiChangesMade" class="liquid-editor" [originalModel]="originalModel" [modifiedModel]="modifiedModel" [ngStyle]="JSONeditorStyle" [options]="diffEditorOptions" automaticLayout></ngx-monaco-diff-editor> 
            <app-template-ai
                *ngIf="showAiTemplate" 
                class="template-ai"
                [templateName]="templateName"
                [namespace]="namespace"
                [templateCode]="templateCode"
                [templateInputData]="templateInputData"
                [templateOutputData]="templateOutputData"
                (triggerCloseAI)="closeAI($event)"
                (triggerAiResponse)="handleAiResponse($event)"
                (triggerAiChanges)="loadAiChanges($event)"></app-template-ai>
        </div>
    </div>
    <!-- right column -->
    <div class="right-column">
        <!-- output -->
        <he-text appearance="heading-4" class="title">Expected output</he-text>
        <div class="box">
            <he-text appearance="paragraph" *ngIf="selectedOutputFileName">{{selectedOutputFileName}}</he-text> 
            <he-text appearance="paragraph" *ngIf="!selectedOutputFileName">Expected output JSON</he-text>
            <he-button appearance="command" (click)="removeOutputFile()" >
                <he-icon slot="start" name="errorbadge"></he-icon>
                Remove
            </he-button>
            <he-button appearance="command" (click)="openFileManager('output')">
                <he-icon slot="start" name="download"></he-icon>
                Import file
                <input type="file" id="fileUploadOutput" style="display: none;" (change)="onOutputFileSelected($event)">
            </he-button>
        </div>
        <ngx-monaco-editor class="liquid-editor" [options]="inputOutputEditorOptions" [(ngModel)]="templateOutputData" [ngStyle]="JSONeditorStyle" automaticLayout></ngx-monaco-editor>
        <!-- transformed output -->
        <he-text appearance="heading-4" class="title">Transformed output</he-text>
        <div class="box">
            <he-text *ngIf="checkOutputEquality()" appearance="paragraph" style="color: green;">Executed Output and expected are the same </he-text> 
            <he-text *ngIf="!checkOutputEquality()" appearance="paragraph" style="color: red;">Executed Output and expected are not the same </he-text>
        </div>
        <ngx-monaco-editor class="liquid-editor" [options]="executeEditorOptions" [(ngModel)]="executed_output" [ngStyle]="JSONeditorStyle"></ngx-monaco-editor>
    </div>
</div>