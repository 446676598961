import { Component, ViewChild, ElementRef, AfterViewInit, Input, EventEmitter, Output} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TemplateGenerateAPI, TemplateUpdateAPI } from 'src/app/shared/services/ComponentServices/TemplateChatbotAI-api/template-chatbot-api.service';
import { GenerateTemplateRequestModel } from 'src/app/shared/models/generate-template-request-model';
import { PrompTemplateRequest } from 'src/app/shared/models/template-prompt-request-model';


@Component({
  selector: 'app-template-ai',
  templateUrl: './template-ai.component.html',
  styleUrls: ['./template-ai.component.css']
})
export class TemplateAiComponent {
  aiChangesMade: boolean = false;
  aiResponse: string = "";
  @Input({ required: true }) namespace: string = "";
  @Input({ required: true }) templateName: string = "";
  prompt: string = "";
  @Input({ required: true }) templateCode: string = "";
  @Input({ required: true }) templateInputData: string = "";
  @Input({ required: true }) templateOutputData: string = "";
  constructor(
    private router: ActivatedRoute,
    private templategenerateservice: TemplateGenerateAPI,
    private templatepromptservice: TemplateUpdateAPI,
  ) {}

  @ViewChild('inputFile') inputFileElement!: ElementRef;
  @ViewChild('outputFile') outputFileElement!: ElementRef;

  @Output() triggerCloseAI = new EventEmitter<boolean>();
  @Output() triggerAiResponse = new EventEmitter<string>();
  @Output() triggerAiChanges = new EventEmitter<boolean>();


  triggerClose(): void {
    this.triggerCloseAI.emit(false);
  }  

    // UPDATE VARIABLES AS USER TYPES
    updateValue(event: Event): void {
      const target = event.target as HTMLInputElement;
      if (target.id === 'copilot-input') {
        this.prompt = target.value;
    }
  }
  
    handleAiSubmission(event: Event) {
      const keyboardEvent = event as KeyboardEvent;
      if (keyboardEvent.key === 'Enter') {
        keyboardEvent.preventDefault(); 
      }
      this.promptAI();
      this.prompt = '';
      const input = document.getElementById('copilot-input') as HTMLTextAreaElement;
      input.value = '';
  }


    // METHOD TO OPEN LOCAL FILE MANAGER
    openFileManager(fileType: string): void {
      const fileInput: HTMLElement = document.getElementById(fileType) as HTMLElement;
      fileInput.click();

  }


  ngOnInit(): void {
  }


    // Tempate Generation 
          // ADD A REGENERATE TEMPLATE BUTTON 

  generateAiTemplate(): void {

  var generateRequest : GenerateTemplateRequestModel = {
    OutputJSON: this.templateOutputData,
    InputJSON: this.templateInputData
  }
  this.triggerAiChanges.emit(true);
  
  this.templategenerateservice.PostTemplateUpload(generateRequest).subscribe({
    next: (response: any) => {
      this.aiResponse = response.liquidTemplate;
      this.triggerAiResponse.emit(this.aiResponse);
      this.triggerAiChanges.emit(false);
    },
    error: (error) => {
      if (error.error instanceof ErrorEvent) {
        console.error('Error Event:', error.error.message);
      } else {
        console.error(`Server-side error: ${error.status} - ${error.message}`);
      }
    }
  })
  }


    // Diff Editor and Accept/Decline Edits Logic

    originalModel = {
      code: this.templateCode,
      language: 'liquid'
    };
  
    modifiedModel = {
      code: 'console.log("Hello, Monaco!")',
      language: 'liquid'
   };
  
    diffEditorOptions = {
      theme: 'vs-dark', 
      automaticLayout: true,
      readOnly: true,
      renderSideBySide: false // ASSIGN TO VARIABLE 
    };
    
    promptAI(): void {
      
      this.triggerAiChanges.emit(true);
      var promptRequest: PrompTemplateRequest = {
        UpdateWanted: this.prompt,
        Template: JSON.stringify(this.templateCode)
      }

      this.templatepromptservice.PostTemplateUpdate(promptRequest).subscribe({
        next: (response: any) => {
          this.triggerAiResponse.emit(response.liquidTemplate);
          this.triggerAiChanges.emit(false);
        }
      })

    }


}







