export const API_ENDPOINT = {
  EXECUTE_TEMPLATE_POST: 'TransformationCenter/ExecuteTemplate',
  getTemplateDetailsEndpoint(namespace: string): string {
    return `TransformationCenter/TemplateDetails/Namespace=${namespace}`;
  },
  UPLOAD_TEMPLATE_POST: 'TransformationCenter/UploadTemplate',
  DOWNLOAD_TEMPLATE_POST: 'TransformationCenter/DownloadTemplate',
  DELETE_TEMPLATE_POST: 'TransformationCenter/DeleteTemplate',
  GENERATE_TEMPLATE_POST: 'api/TaaSBot/GenerateTemplate',
  UPDATE_TEMPLATE_POST: 'api/TaaSBot/UpdateTemplate'
};
