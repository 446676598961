  <!-- View Template Panel -->
  <he-fly-in-panel
  id="viewPanel"
  #viewPanel
  style="--size: 1300px;"
  heading={{templateName}} >
  	<he-command-bar>
		<he-button appearance="command" (click)="editTemplate()">
			<he-icon slot="start" name="edit"></he-icon>
		Edit
		</he-button>
		<he-button appearance="command" (click)="confirmDeletePOPUP()">
			<he-icon slot="start" name="delete"></he-icon>
		Delete
		</he-button>
		<he-button appearance="command" (click)="downloadTemplate()">
			<he-icon slot="start" name="download"></he-icon>
		Download
		</he-button>
	</he-command-bar>
	<he-divider></he-divider>
	<he-progress *ngIf="isLoading" label="Progress" indeterminate></he-progress>
	
	<ngx-monaco-editor *ngIf="!isLoading" class="liquid-editor" [options]="liquidEditorOptions" [(ngModel)]="liquidCode" [ngStyle]="liquidEditorStyle"></ngx-monaco-editor>
	<he-divider></he-divider>
	<!-- Template Info Table -->
	<he-accordion *ngIf="!isLoading">
		<he-accordion-item *ngIf="selectedRows.length > 0">
			<strong slot="heading">Template Info</strong>
			<div class="tables-container">
			<table class="table-style">
				<thead>
					<tr>
						<th class="cell-padding">Template Name</th>
						<th class="cell-padding">Modified</th>
						<th class="cell-padding">Created On</th>
					</tr>
					</thead>
					<tbody>
						<tr>
							<td class="cell-padding">{{templateName}}</td>
							<td class="cell-padding">{{selectedRows[0].cells.field5}}</td>
							<td class="cell-padding">{{selectedRows[0].cells.field3}}</td>
						</tr>
					</tbody>
					</table>
					<table class="table-style">
					<thead>
						<tr>
							<th class="cell-padding">Namespace</th>
							<th class="cell-padding">Modified By</th>
							<th class="cell-padding">Owner</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td class="cell-padding">{{namespace}}</td>
							<td class="cell-padding">{{selectedRows[0].cells.field4}}</td>
							<td class="cell-padding">{{selectedRows[0].cells.field6}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</he-accordion-item>
	</he-accordion>
  <div slot="footer">
    <he-button #viewPanel id="close-large" appearance="primary" (click)="closeViewPanel()">Close</he-button>
  </div>
</he-fly-in-panel>


<app-delete-template
[selectedRows]="selectedRows" 
[templateName]="templateName"
[namespace]="namespace"
(triggerDelete)="deleteTemplateAfterConfirm()"
(triggerClose)="closeViewPanel()"></app-delete-template>