import { Component, ChangeDetectorRef, ElementRef, EventEmitter, Output, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';

import { TemplateManagerComponent } from '../template-manager/template-manager.component';
import { TemplateViewerComponent } from '../template-viewer/template-viewer.component';

@Component({
  selector: 'app-delete-template',
  templateUrl: './delete-template.component.html',
  styleUrls: ['./delete-template.component.css']
})
export class DeleteTemplateComponent implements AfterViewInit {
  @Input() templateName: string = '';
  @Input() namespace: string = '';
  @Input() selectedRows: any[] = [];

  @Output() triggerDelete = new EventEmitter<void>();
  @Output() triggerClose = new EventEmitter<void>();


  @ViewChild(TemplateManagerComponent) templateManager!: TemplateManagerComponent;
  @ViewChild(TemplateViewerComponent, { static: false }) templateViewer!: TemplateViewerComponent;
  // CONFIRM DELETE POP-UP
  @ViewChild('confirmDelete') confirmDelete!: ElementRef;

  ngAfterViewInit(): void {
  }

  confirmDeleteFN(): void {
    // Show the dialog
    this.confirmDelete.nativeElement.show();
    // Prevent the dialog from closing when clicking on the overlay
    this.confirmDelete.nativeElement.addEventListener('he-dialog-request-close', this.preventOverlayClose);
  }

  closeDeleteFN(): void {
    // Hide the dialog
    this.confirmDelete.nativeElement.hide();
    // Remove the event listener to clean up
    this.confirmDelete.nativeElement.removeEventListener('he-dialog-request-close', this.preventOverlayClose);
  }

  private preventOverlayClose(event: any): void {
    if (event.detail.source === 'overlay') {
      event.preventDefault();
    }
  }

  deleteTemplate(): void {
    this.triggerDelete.emit();
  }

  closeViewPanel(): void {
    this.triggerClose.emit();
  }

}
