import { createScope } from '@harmony/enablers/utilities/scope';
import theme from '@harmony/enablers/themes/partner-center';
import cssUtilities from '@harmony/enablers/utilities/css';
import { Button } from '@harmony/enablers/components/button/button';
import { TextField } from '@harmony/enablers/components/text-field/text-field';
import { Text } from '@harmony/enablers/components/text/text';
import { Dropdown } from '@harmony/enablers/components/dropdown/dropdown';
import { Popup } from '@harmony/enablers/components/popup/popup';
import { Menu } from '@harmony/enablers/components/menu/menu';
import { MenuItem } from '@harmony/enablers/components/menu-item/menu-item';
import { Select } from '@harmony/enablers/components/select/select';
import { Icon } from '@harmony/enablers/components/icon/icon';
import { ProgressRing } from '@harmony/enablers/components/progress-ring/progress-ring';
import { Option } from '@harmony/enablers/components/option/option';
import { Divider } from '@harmony/enablers/components/divider/divider';
import { Accordion } from '@harmony/enablers/components/accordion/accordion';
import { AccordionItem } from '@harmony/enablers/components/accordion-item/accordion-item';
import { Tooltip } from '@harmony/enablers/components/tooltip/tooltip';
import { DatePicker } from '@harmony/enablers/components/date-picker/date-picker';
import { Calendar } from '@harmony/enablers/components/calendar/calendar';
import { DataGrid } from '@harmony/enablers/components/data-grid/data-grid';
import { Radio } from '@harmony/enablers/components/radio/radio';
import { Skeleton } from '@harmony/enablers/components/skeleton/skeleton';
import { TextArea } from '@harmony/enablers/components/text-area/text-area';
import { Layout } from '@harmony/enablers/components/layout/layout';
import { Badge } from '@harmony/enablers/components/badge/badge';
import { Breadcrumb } from '@harmony/enablers/components/breadcrumb/breadcrumb';
import { BreadcrumbItem } from '@harmony/enablers/components/breadcrumb-item/breadcrumb-item';
import { Card } from '@harmony/enablers/components/card/card';
import { FlyInPanel } from '@harmony/enablers/components/fly-in-panel/fly-in-panel';
import { NavHeader } from '@harmony/enablers/components/nav-header/nav-header';
import { Persona } from '@harmony/enablers/components/persona/persona';
import { PaneGroup } from '@harmony/enablers/components/pane-group/pane-group';
import { PushPane } from '@harmony/enablers/components/push-pane/push-pane';
import { SearchBox } from '@harmony/enablers/components/search-box/search-box';
import { TaskMenu } from '@harmony/enablers/components/task-menu/task-menu';
import { TaskItem } from '@harmony/enablers/components/task-item/task-item';
import { QuickStartCard } from '@harmony/enablers/components/quick-start-card/quick-start-card';
import { MessageBar } from '@harmony/enablers/components/message-bar/message-bar';
import { PageTitle } from '@harmony/enablers/components/page-title/page-title';
import { Progress } from '@harmony/enablers/components/progress/progress';
import { Checkbox } from '@harmony/enablers/components/checkbox/checkbox';
import { Pagination } from '@harmony/enablers/components/pagination/pagination';
import { Dialog } from '@harmony/enablers/components/dialog/dialog';
import { Tabs } from '@harmony/enablers/components/tabs/tabs';
import { Tab } from '@harmony/enablers/components/tab/tab';
import { TabPanel } from '@harmony/enablers/components/tab-panel/tab-panel';
import { Callout } from '@harmony/enablers/components/callout/callout';
import { Combobox } from '@harmony/enablers/components/combobox/combobox';
import { Copilot } from '@harmony/enablers/components/copilot/copilot'

// Create a scope with a default "app_" prefix and a root element of <html>
// Now all elements will be referenced with `<app_he-button>` for example.
export const scope = createScope({
  // Make sure to initialize one level above your app-root
  rootElement: document.querySelector('body') as HTMLBodyElement,
  styles: [theme, cssUtilities],
  components: [
    Button,
    TextField,
    Text,
    Popup,
    Dropdown,
    Menu,
    MenuItem,
    Select,
    Icon,
    ProgressRing,
    Option,
    Divider,
    Accordion,
    AccordionItem,
    Tooltip,
    DatePicker,
    Calendar,
    DataGrid,
    Radio,
    Skeleton,
    TextArea,
    Layout,
    Badge,
    Breadcrumb,
    BreadcrumbItem,
    Card,
    FlyInPanel,
    NavHeader,
    Persona,
    PaneGroup,
    PushPane,
    SearchBox,
    TaskMenu,
    TaskItem,
    QuickStartCard,
    MessageBar,
    PageTitle,
    Progress,
    Checkbox,
    Pagination,
    Dialog,
    Tabs,
    Tab,
    TabPanel,
    Callout,
    Combobox,
    Copilot
  ],
});
