import { Component, ChangeDetectorRef, ElementRef, EventEmitter, Output, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { TemplateDetailsAPI } from 'src/app/shared/services/ComponentServices/TemplateManagement-api/template-management-api.service';
import { TemplateDetailsModel } from 'src/app/shared/models/template-details-model';
import { TransformationModelUpload } from 'src/app/shared/models/transformation-model-upload';
import { TemplateUploadAPI } from 'src/app/shared/services/ComponentServices/TemplateManagement-api/template-management-api.service';
import { TemplateDownloadAPI } from 'src/app/shared/services/ComponentServices/TemplateManagement-api/template-management-api.service';
import { DeleteTemplateAPI } from 'src/app/shared/services/ComponentServices/TemplateManagement-api/template-management-api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-upload-side-bar',
  templateUrl: './upload-side-bar.component.html',
  styleUrls: ['./upload-side-bar.component.css']
})
export class UploadSideBarComponent {
  selectedFiles: FormData[] = [];
  isDisabled: boolean = true;
  disableAll: boolean = true;
  templateDeleteSuccess: boolean = false;
  isMultipleTemplates: boolean = false;
  failedUpload: boolean = false;
  successfulUpload: boolean = false; // used to display success message
  namespace: string = ''
  templateName: string = '';
  lockSuccessMessage: boolean = false;
  deletingTemplateName: string = '';
  nameonscreen: string = '';
  selectedRows: any = [];
  templateDetailsARR: TemplateDetailsModel[] = [];
  selectedFileNames: string[] = [];
  constructor(
    private templatedetailserivce: TemplateDetailsAPI,
    private templateuploadservice: TemplateUploadAPI,
    private templatedownloadservice: TemplateDownloadAPI,
    private templatedeleteservice: DeleteTemplateAPI,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {}
  
  availableNamespaces = {myNamespaces: ["SupplierCollab"]}; // used to populate namespace dropdown for create and upload features

  @ViewChild('uploadPanel') uploadPanel!: ElementRef;
  openUploadPanel() {
    this.uploadPanel.nativeElement.show();
  }
  closeUploadPanel() {
    this.uploadPanel.nativeElement.hide();
  }

    // UPDATE VARIABLES AS USER TYPES
    updateValue(event: Event): void {
      const target = event.target as HTMLInputElement;
      if (target.id === 'templateNameInput') {
        this.templateName = target.value;
      } else if (target.id === 'templateNamespaceInput') {
        this.namespace = target.value;
        console.log(this.namespace);
      }
    }
  ngOnInit(): void {

    this.uploadPanel.nativeElement.addEventListener('he-fly-in-request-close', (event: CustomEvent) => {
      if (event.detail.source === 'overlay') {
        event.preventDefault();
      }
    });

  }

     // METHOD TO OPEN LOCAL FILE MANAGER
     openFileManager(): void {
      this.selectedFiles = [];
      const fileInput: HTMLElement = document.getElementById('fileUpload') as HTMLElement;
      this.selectedFileNames = [];
      fileInput.click();
    }

  // METHOD TO HANDLE FILE SELECTION 

  onFileSelected(event: any): void {
    const files: FileList = event.target.files;
    if (files.length > 0) {
      // Check if only one file is selected
      if (files.length === 1) {
        const file = files[0];
        const formData = new FormData();
        formData.append('TemplateName', file.name); 
        formData.append('Template', file);
        this.selectedFiles.push(formData);
        console.log('1 file selected.');
        this.selectedFileNames.push(file.name);
      } else {
        // Handle multiple files as before
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const formData = new FormData();
          formData.append('TemplateName', file.name); 
          formData.append('Template', file);
          this.selectedFiles.push(formData);
          this.selectedFileNames.push(file.name);
        }
        console.log(`${files.length} file(s) selected.`);
      }
    } else {
      console.error('No files selected!');
      return;
    }
  }

  // METHOD THAT SUBMITS TO POST TEMPLATE UPLOAD API FUNCTION
  submitUploadTemplate(): void {
    if(this.namespace === '' ){
      console.error('Namespace is empty!');
      return;
    }
    this.postDatatoTemplateUploadAPI();
    setTimeout(() => {
      this.ngOnInit();
    }, 2000);
    this.successfulUpload = true;
    this.failedUpload = false;
  }

  // UPLOAD TEMPLATE
  postDatatoTemplateUploadAPI(): void {
    if (this.selectedFiles.length === 0) {
      console.error('No files prepared for upload!');
      return;
    }
  
    this.selectedFiles.forEach((formData, index) => {
      formData.append('Namespace', this.namespace);
      var templateToUploads : TransformationModelUpload = {
        Namespace: formData.get('Namespace') as string,
        TemplateName: formData.get('TemplateName') as string,
        Template: formData.get('Template') as Blob
      }
      this.templateuploadservice.PostTemplateUpload(templateToUploads).subscribe({
        next: (response: TransformationModelUpload) => {
          console.log(`Response for file ${index + 1}:`, response);
        },
        error: (error) => {
          console.error(`Error for file ${index + 1}:`, error);
          if (error.error instanceof ErrorEvent) {
            console.error('Error Event:', error.error.message);
          } else {
            console.error(`Server-side error: ${error.status} - ${error.message}`);
          }
        }
      });
    });
  
    // Clear the array after submission
    this.selectedFiles = [];
  }
  refreshPage(): void {
    window.location.reload();
   }

}

