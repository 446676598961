import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_ENDPOINT } from '../../../constants/api-constants';
import { HttpCoreService } from '../http/http-core.service';
import { TransformationModelDownload } from 'src/app/shared/models/transformation-model-download';
import { TransformationModelUpload, toFormData } from 'src/app/shared/models/transformation-model-upload';
import { TransformationModelExecute } from 'src/app/shared/models/transformation-model-execute';
import { TransformationModelDelete } from 'src/app/shared/models/transformation-model-delete';
@Injectable({
    providedIn: 'root'
})
export class TemplateUploadAPI {
    constructor(private httpCore: HttpCoreService) { }
    
    PostTemplateUpload(body: TransformationModelUpload): Observable<any> {
        const formData = toFormData(body);
        const headers = { 'enctype': 'multipart/form-data' };
        return this.httpCore.post(API_ENDPOINT.UPLOAD_TEMPLATE_POST, formData);
    }

}

@Injectable({
    providedIn: 'root'
})
export class TemplateDownloadAPI {
    constructor(private httpCore: HttpCoreService) { }
    
    PostTemplateDownload(body: TransformationModelDownload): Observable<any> {
        return this.httpCore.post(API_ENDPOINT.DOWNLOAD_TEMPLATE_POST, body);
    }
}

@Injectable({
  providedIn: 'root'
})
export class TemplateDetailsAPI {
  constructor(private httpCore: HttpCoreService) { }
  
  GetTemplateDetails(namespace: string): Observable<any>{
    return this.httpCore.get(
      API_ENDPOINT.getTemplateDetailsEndpoint(namespace)
    );
  }
}

@Injectable({
  providedIn: 'root'
})
export class ExecuteTemplateAPI {
  constructor(private httpCore: HttpCoreService) { }
  
  postExecuteTemplate(body: TransformationModelExecute): Observable<any>{
    return this.httpCore.post(
      API_ENDPOINT.EXECUTE_TEMPLATE_POST,
      body
    );
  }
}

@Injectable({
  providedIn: 'root'
})
export class DeleteTemplateAPI {
  constructor(private httpCore: HttpCoreService) { }
  
  postDeleteTemplate(body: TransformationModelDelete): Observable<any>{
    return this.httpCore.post(
      API_ENDPOINT.DELETE_TEMPLATE_POST,
      body
    );
  }
}
