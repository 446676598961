<!-- upload side bar  -->
<he-fly-in-panel  #uploadPanel heading="Template upload" class="fly-in-overview fly-in-footer" >
    <p>Provide the details for the new template as required. </p>
  <p> Click submit to proceed to the editor page for your new template.</p>

  

  <he-button appearance="command" style="border: 1px solid;width: 175px;" (click)="openFileManager()">
    <he-icon slot="start" name="upload"></he-icon>
    Choose template file
    <input type="file" id="fileUpload" style="display: none;" multiple (change)="onFileSelected($event)">
</he-button><br/><br/>
    
<he-text appearance="paragraph" *ngFor="let fileName of selectedFileNames">{{fileName}}</he-text>

<he-select required label="Namespace" value="" id="templateNamespaceInput" (he-input)="updateValue($event)"> <!-- Namespace dropdown -->
    <he-option *ngFor="let eachVal of availableNamespaces['myNamespaces']" [value]="eachVal">{{eachVal}}</he-option>
</he-select>

<he-message-bar *ngIf="successfulUpload" appearance="success" open no-close-button style="margin: 14px 0;">
    Your changes have been saved!
</he-message-bar>

<he-message-bar *ngIf="failedUpload" duration="5000" appearance="error" open no-close-button style="margin: 14px 0;">
    Please Upload a .liquid File
</he-message-bar>


<he-button slot="footer" appearance="primary" (click)="submitUploadTemplate(); refreshPage()">Submit</he-button>
<he-button slot="footer" (click)="closeUploadPanel()">Cancel</he-button>


</he-fly-in-panel>