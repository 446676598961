export interface TransformationModelUpload {
  Namespace: string;
  TemplateName: string;
  Template: Blob;
}

export function toFormData(model: TransformationModelUpload): FormData {
  const formData = new FormData();
  formData.append('Namespace', model.Namespace);
  formData.append('TemplateName', model.TemplateName);
  formData.append('Template', model.Template);
  return formData;
}
