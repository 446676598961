import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DeleteTemplateComponent } from './components/delete-template/delete-template.component';
import { TemplateManagerComponent } from './components/template-manager/template-manager.component';
import { TemplateEditorComponent } from './components/template-editor/template-editor.component';


const routes: Routes = [
  {
    path: '',
    component: TemplateManagerComponent
  },
  {
    path: '**',
    redirectTo: "DesignCenter"
  },
  {
    path:'templatemanager',
    component: TemplateManagerComponent
  },
  {
    path: 'templateeditor',
    component: TemplateEditorComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
