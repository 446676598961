import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_ENDPOINT } from '../../../constants/api-constants';
import { HttpCoreService } from '../http/http-core.service';
import { GenerateTemplateRequestModel } from 'src/app/shared/models/generate-template-request-model';
import { PrompTemplateRequest } from 'src/app/shared/models/template-prompt-request-model';
@Injectable({
    providedIn: 'root'
})
export class TemplateGenerateAPI {
    constructor(private httpCore: HttpCoreService) { }
    
    PostTemplateUpload(body: GenerateTemplateRequestModel): Observable<any> {
        return this.httpCore.post(API_ENDPOINT.GENERATE_TEMPLATE_POST, body, undefined, true);
    }
}

@Injectable({
    providedIn: 'root'
})
export class TemplateUpdateAPI {
    constructor(private httpCore: HttpCoreService) { }
    
    PostTemplateUpdate(body: PrompTemplateRequest): Observable<any> {
        return this.httpCore.post(API_ENDPOINT.UPDATE_TEMPLATE_POST, body, undefined, true);
    }
}
