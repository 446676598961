<!-- create side bar  -->

<he-fly-in-panel 
	#createPanel
      heading="Create a new template"
	  style="--size: var(--he-panel-size-medium);"
      class="fly-in-overview fly-in-footer" > 
	  <he-text appearance="heading-5">Template name and namespace</he-text>
      <p>
        Provide the details for the new template as required. Click Submit to
        proceed to the editor page for your new template.
      </p>
	<he-text-field required label="Template name" id="templateNameInput" (input)="updateValue($event)" [(ngModel)]="templateName"></he-text-field>

	<he-select required label="Namespace" value="" id="templateNamespaceInput" (he-input)="updateValue($event)"> <!-- Namespace dropdown -->
		<he-option *ngFor="let eachVal of availableNamespaces['myNamespaces']">{{eachVal}}</he-option>
	</he-select>

	
	

	<br />
	<he-text appearance="heading-5">Choose input and output files (optional)</he-text>
	<br />



<div class="input-box">
	<he-button appearance="command" style="border: 1px solid;width: 175px;" (click)="openFileManager('input')">
		<he-icon slot="start" name="upload"></he-icon>
		Choose input file
		<input type="file" id="fileUploadInput" style="display: none;" (change)="onInputFileSelected($event)">
	</he-button>
	<he-text appearance="paragraph" *ngIf="selectedInputFileName">{{selectedInputFileName}}</he-text> 
	<br/>
	<he-button class="remove-button" *ngIf="selectedInputFileName" appearance="command" style="border: 1px solid;width: 100px; align-self: flex-end;" (click)="removeInputFile()">
		Remove
	</he-button>
</div> <br/>
 
<div class="output-box">
	<he-button appearance="command" style="border: 1px solid;width: 175px;" (click)="openFileManager('output')">
		<he-icon slot="start" name="upload"></he-icon>
		Choose output file
		<input type="file" id="fileUploadOutput" style="display: none;" (change)="onOutputFileSelected($event)">
	</he-button>
	<he-text appearance="paragraph" *ngIf="selectedOutputFileName">{{selectedOutputFileName}}</he-text> 
	<br/>
	<he-button class="remove-button" *ngIf="selectedOutputFileName" appearance="command" style="border: 1px solid;width: 100px; align-self: flex-end;" (click)="removeOutputFile()">
		Remove
	</he-button>
</div>
<he-button slot="footer" appearance="primary" (click)="sendToEditor()">Submit</he-button>
<he-button slot="footer" (click)="closeCreatePanel()">Cancel</he-button>


</he-fly-in-panel>
